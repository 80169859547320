import { Select } from 'components/Select'
import { useTranslation } from 'react-i18next'

export function LanguageSelect() {
  const { i18n } = useTranslation()

  return (
    <div className="absolute right-3 top-3 z-10">
      <Select
        className="border-gray-600 bg-transparent"
        defaultValue={i18n.language}
        onChange={e => {
          const lang = e.currentTarget.value
          localStorage.setItem('lang', lang)
          i18n.changeLanguage(lang)
        }}
      >
        <option value="en">🇺🇸</option>
        <option value="es">🇪🇸</option>
        <option value="ru">🇷🇺</option>
      </Select>
    </div>
  )
}
