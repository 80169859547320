export function HelpLinks() {
  return (
    <div className="mt-4 flex justify-end gap-2 text-sm text-gray-400">
      <a className="hover:underline" target="_blank" href="https://help.complicore.co?ref=login" rel="noreferrer">
        Help
      </a>
      <a className="hover:underline" target="_blank" href="https://complicore.co/privacy" rel="noreferrer">
        Privacy
      </a>
      <a className="hover:underline" target="_blank" href="https://complicore.co/terms" rel="noreferrer">
        Terms
      </a>
    </div>
  )
}
