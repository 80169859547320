import LoginPage from 'components/Login/LoginPage'
import { LoginProvider } from 'contexts/LoginProvider'
import { getAccountFromContext } from 'lib/api'
import { GetServerSideProps } from 'next'
import { Account } from 'types/Account'

export type LoginPageProps = {
  account?: Account
}

export default function Login({ account }: LoginPageProps) {
  return (
    <LoginProvider>
      <LoginPage account={account} />
    </LoginProvider>
  )
}

export const getServerSideProps: GetServerSideProps = async context => {
  return {
    props: { account: await getAccountFromContext(context) },
  }
}
