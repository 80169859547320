import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { Loader } from 'components/Loader'
import { Text } from 'components/Text'
import { Title } from 'components/Title'
import { useLoginContext } from 'hooks/useLoginContext'
import { Dispatch, SetStateAction, useState } from 'react'

const LOGIN_CREDENTIALS = [
  { email: 'admin@complicore.co', role: 'Admin' },
  { email: 'manager@example.com', role: 'Manager' },
  { email: 'specialist@example.com', role: 'Specialist' },
  { email: 'operations@example.com', role: 'Operations' },
  { email: 'provider@example.com', role: 'Provider' },
  { email: 'ip@example.com', role: 'Insurance Producer' },
  { email: 'inspector@example.com', role: 'Vehicle Inspector' },
  { email: 'broker@complicore.co', role: 'Broker' },
]

type Props = {
  setShouldShowDevLogin: Dispatch<SetStateAction<boolean>>
}

export function DevelopmentLogin({ setShouldShowDevLogin }: Props) {
  const { requestLogin, handleLogin } = useLoginContext()
  const [loading, setLoading] = useState(false)

  const onLogin = async (email: string) => {
    setLoading(true)

    try {
      await requestLogin(email, false, true)
      await handleLogin({ email, token: '917682' })
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return <Loader />
  }

  return (
    <div className="w-full">
      <div className="flex w-full flex-col items-center justify-center">
        <Title center type="h1" weight="bold" size="2xl" className="mb-6" ariaLabel="sign in message">
          Login As
        </Title>
      </div>
      <div className="mb-4 grid grid-cols-1 gap-2">
        {LOGIN_CREDENTIALS.map(({ email, role }) => (
          <Button loading={loading} key={email} onClick={() => onLogin(email)} variant="outline">
            <Box className="flex flex-col justify-center">
              <Title type="h4" size="lg" className="text-left">
                {role}
              </Title>
              <Text weight="normal" size="sm" className="text-left">
                {email}
              </Text>
            </Box>
          </Button>
        ))}
      </div>
      <div className="flex justify-center">
        <Button onClick={() => setShouldShowDevLogin(false)} className="py-2" variant="link">
          Use another email
        </Button>
      </div>
    </div>
  )
}
