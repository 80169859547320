import { Card } from 'components/Card'
import { SidebarLogo } from 'components/sidebar/SidebarLogo'
import { LoginState } from 'contexts/LoginProvider'
import { AnimatePresence, motion } from 'framer-motion'
import { useLoginContext } from 'hooks/useLoginContext'
import { useRouter } from 'next/router'
import { LoginPageProps } from 'pages/login'
import { Fragment, useEffect, useState } from 'react'
import { DevelopmentLogin } from './DevelopmentLogin'

import { MainLayout } from 'layouts/MainLayout'
import { HelpLinks } from './HelpLinks'
import { LanguageSelect } from './LanguageSelect'
import { LoginActions } from './LoginActions'
import { LoginForm } from './LoginForm'

export default function LoginPage({ account }: LoginPageProps) {
  const router = useRouter()
  const { encodedToken, values, submit } = useLoginContext()
  const [shouldShowDevLogin, setShouldShowDevLogin] = useState(process.env.NODE_ENV === 'development')

  useEffect(() => {
    if (account) {
      router.replace((router.query.next as string) || '/')
      return
    }

    document.getElementById('email')?.focus()
  }, [account, router])

  if (account || (encodedToken && !values?.email)) {
    return (
      <Fragment>
        <MainLayout loading />
        <MagicLinkHandler />
      </Fragment>
    )
  }

  return (
    <div className="dark:bg-dark-900 relative flex min-h-screen justify-center overflow-hidden bg-gray-800 px-4 py-32">
      <LanguageSelect />
      <AnimatePresence>
        <motion.div initial={{ y: 100, opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ y: -100, opacity: 0 }}>
          <div className="pb-4 text-xl">
            <SidebarLogo />
          </div>
          <Card className="relative w-screen max-w-sm space-y-8 px-6 pb-6 pt-12 shadow-lg">
            <form onSubmit={submit}>
              <div className="rounded-md">
                {shouldShowDevLogin ? (
                  <DevelopmentLogin setShouldShowDevLogin={setShouldShowDevLogin} />
                ) : (
                  <LoginForm />
                )}
                <LoginActions />
              </div>
            </form>
          </Card>
          <HelpLinks />
        </motion.div>
      </AnimatePresence>
    </div>
  )
}

function MagicLinkHandler() {
  const { encodedToken, setStatus, handleLogin } = useLoginContext()

  useEffect(() => {
    if (!encodedToken) return
    try {
      const decodedToken = atob(encodedToken)
      const [email, token] = decodedToken.split(':')

      if (!email?.length || !token?.length) {
        setStatus(LoginState.InvalidMagicLink)
      } else {
        handleLogin({ email, token })
      }
    } catch {
      setStatus(LoginState.InvalidMagicLink)
    }
  }, [encodedToken, handleLogin, setStatus])

  return null
}
