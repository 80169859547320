import { Button } from 'components/Button'
import { LoginState } from 'contexts/LoginProvider'
import { useLoginContext } from 'hooks/useLoginContext'
import { t } from 'i18next'
import { useRouter } from 'next/router'
import { HiOutlineArrowLeft } from 'react-icons/hi'

const shouldShowLoginActions = (status: LoginState) =>
  [
    LoginState.LoginCode,
    LoginState.InvalidLoginCode,
    LoginState.MagicLink,
    LoginState.InvalidMagicLink,
    LoginState.DeviceLogin,
    LoginState.ExpiredDeviceLogin,
    LoginState.ProviderNotSetup,
  ].includes(status)

const shouldHideResendButton = (status: LoginState) =>
  [LoginState.ProviderNotSetup, LoginState.DeviceLogin, LoginState.ExpiredDeviceLogin].includes(status)

export function LoginActions() {
  const { requestLogin, setStatus, values, status } = useLoginContext()
  const router = useRouter()

  const onUseAnotherEmail = () => {
    setStatus(LoginState.Email)
    router.replace('/login', undefined)
  }

  if (!shouldShowLoginActions(status)) return null

  return (
    <div className="mt-12 inline-flex w-full items-center justify-between gap-4">
      <Button variant="link" icon={HiOutlineArrowLeft} onClick={onUseAnotherEmail}>
        {t('useAnotherEmailMsg')}
      </Button>
      {!shouldHideResendButton(status) && (
        <Button
          disabled={values.email?.length < 1}
          variant="link"
          onClick={() => requestLogin(values.email, true, true)}
          className="text-gray-500"
        >
          Resend
        </Button>
      )}
    </div>
  )
}
